import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { DEFAULT_QUERY_OPTIONS } from "@src/appV2/Streaks/constants";
import {
  type AgentStreaksBonusApiResponse,
  type GetAgentStreaksBonusParams,
  getAgentStreaksBonusParamsSchema,
  getAgentStreaksBonusRequestSchema,
  getAgentStreaksBonusResponseSchema,
} from "@src/appV2/Streaks/types";
import type { UseQueryResult } from "@tanstack/react-query";

export function useGetWorkerStreaksBonus(
  params: GetAgentStreaksBonusParams,
  options: UseGetQueryOptions<AgentStreaksBonusApiResponse> = {}
): UseQueryResult<AgentStreaksBonusApiResponse> {
  const { agentId, filter } = getAgentStreaksBonusParamsSchema.parse(params);

  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/agent/${agentId}/streaks/bonus`,
    queryParams: {
      filter,
    },
    requestSchema: getAgentStreaksBonusRequestSchema,
    responseSchema: getAgentStreaksBonusResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_AGENT_STREAKS_BONUS_FAILURE,
    },
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });
}
