import { isDefined } from "@clipboard-health/util-ts";
import { useGetWorkerActiveStreaks, useStreaksEnabledGlobally } from "@src/appV2/Streaks";
import { useGetStreaksSettings, useGetWorkerStreaksProgress } from "@src/appV2/Streaks/api";
import { type GetStreakAlertMessage } from "@src/appV2/Streaks/types";
import {
  generateStreakAlertMessage,
  getActiveStreakByDate,
  getPayBoostActiveStreaks,
  isShiftQualifiesForStreakBonus,
} from "@src/appV2/Streaks/utils";
import { isEmpty } from "lodash";

export interface StreakAlertMessage {
  message?: string;
  shiftIsQualifiesForStreakBonus: boolean;
}

export function useGetStreakAlertInfo(params: GetStreakAlertMessage): StreakAlertMessage {
  const {
    shiftStartTime,
    shiftEndTime,
    workerId,
    workplaceId,
    onlyCheckActiveStreak,
    isOpenShift,
    isForFacilityOpenShift,
  } = params;
  const isStreaksEnabledGlobally = useStreaksEnabledGlobally();
  const hasAllRequiredAttributes =
    isStreaksEnabledGlobally &&
    !isEmpty(workplaceId) &&
    isDefined(shiftStartTime) &&
    isDefined(shiftEndTime);

  const { data: activeStreaksResponse, isSuccess: isActiveStreaksCallSuccessful } =
    useGetWorkerActiveStreaks(
      {
        agentId: workerId,
        filter: {
          facilityIds: [workplaceId],
        },
      },
      { enabled: hasAllRequiredAttributes }
    );

  const { data: inProgressStreaksResponse, isSuccess: isInProgressStreaksCallSuccessful } =
    useGetWorkerStreaksProgress(
      {
        agentId: workerId,
        filter: {
          date: shiftStartTime,
          facilityIds: [workplaceId],
        },
      },
      { enabled: hasAllRequiredAttributes }
    );

  const { data: streakSettingsResponse, isSuccess: isStreakSettingsCallSuccessful } =
    useGetStreaksSettings(
      {
        facilityId: workplaceId,
      },
      { enabled: hasAllRequiredAttributes }
    );

  const payBoostActiveStreaks = getPayBoostActiveStreaks(activeStreaksResponse?.data);
  const shiftWeekActiveStreak = isDefined(shiftStartTime)
    ? getActiveStreakByDate({
        streaks: payBoostActiveStreaks,
        date: shiftStartTime,
      })
    : undefined;
  const shiftIsQualifiesForStreakBonus = isDefined(shiftStartTime)
    ? isShiftQualifiesForStreakBonus({
        shiftStartTime,
        shiftWeekActiveStreak,
      })
    : false;

  const streakAlertMessage =
    isStreaksEnabledGlobally &&
    isActiveStreaksCallSuccessful &&
    isInProgressStreaksCallSuccessful &&
    isStreakSettingsCallSuccessful &&
    hasAllRequiredAttributes
      ? generateStreakAlertMessage({
          shiftWeekActiveStreak,
          shiftIsQualifiesForStreakBonus,
          shiftStartTime,
          shiftEndTime,
          inProgressStreak: inProgressStreaksResponse?.data?.[0],
          onlyCheckShiftWeekActiveStreak: onlyCheckActiveStreak,
          isOpenShift,
          isForFacilityOpenShift,
          currentWeekActiveStreak: getActiveStreakByDate({
            streaks: payBoostActiveStreaks,
            date: new Date(),
          }),
          streakSettings: streakSettingsResponse.data,
        })
      : undefined;
  return {
    message: streakAlertMessage,
    shiftIsQualifiesForStreakBonus,
  };
}
