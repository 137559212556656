import { useStreaksEnabledGlobally } from "@src/appV2/Streaks/api/featureFlagHooks";
import { useGetWorkerActiveStreaks } from "@src/appV2/Streaks/api/useGetWorkerActiveStreaks";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { isEmpty } from "lodash";

export function useHCPStreakData(facilityIds: string[], date?: Date) {
  const worker = useDefinedWorker();
  const isStreaksEnabledGlobally = useStreaksEnabledGlobally();
  const agentId = worker.userId;
  const { data: streaksResponse, isLoading: isLoadingAgentStreaks } = useGetWorkerActiveStreaks(
    { filter: { facilityIds, date }, agentId },
    {
      enabled: !isEmpty(agentId) && isStreaksEnabledGlobally,
    }
  );
  return {
    isStreaksEnabledGlobally,
    hcpStreaksResponseData: streaksResponse?.data ?? [],
    isLoadingAgentStreaks,
  };
}
